import React from "react";
import Image from "gatsby-image";

import { useStaticQuery, graphql } from "gatsby";

export const TgbofBackground: React.FC = () => {
  const data = useStaticQuery(graphql`
    query Background {
      background: file(name: { eq: "tgbof-bg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div
      className="background fixed bottom-0 left-0 right-0 top-0 z-0 pointer-events-none bg-black"
      style={{ zIndex: -2 }}
    >
      <Image
        fluid={data.background.childImageSharp.fluid}
        imgStyle={{ objectFit: "cover", objectPosition: "center" }}
        className="fixed w-full h-full opacity-50"
      />
    </div>
  );
};
