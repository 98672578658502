import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpotify,
  faYoutube,
  faBandcamp,
  faInstagram,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import classNames from "classnames";
import { motion } from "framer-motion";

const icons = [
  {
    icon: faSpotify,
    title: "Spotify",
    link: "https://open.spotify.com/artist/25qVye5bxWrm3MqrKpn6v8",
  },
  {
    icon: faYoutube,
    title: "YouTube",
    link: "https://www.youtube.com/channel/UC6mXkBr3o37ZD3svHp20CRg",
  },
  {
    icon: faInstagram,
    title: "Instagram",
    link: "https://instagr.am/smallstridesband",
  },
  {
    icon: faFacebook,
    title: "Facebook",
    link: "https://www.facebook.com/small-strides-404021493372583/",
  },
  {
    icon: faBandcamp,
    title: "Bandcamp",
    link: "https://smallstrides.bandcamp.com",
  },
];

export const Social: React.FC<{ className?: string }> = ({ className }) => (
  <motion.div
    variants={{
      show: {
        transition: {
          staggerChildren: 0.1,
        },
      },
    }}
    initial="hidden"
    animate="show"
    className={classNames(
      "flex flex-wrap items-center justify-center mx-auto text-white mt-10",
      className
    )}
  >
    {icons.map((icon) => (
      <motion.a
        className="px-4 group"
        href={icon.link}
        title={`Small Strides on ${icon.title}`}
        variants={{
          hidden: { opacity: 0, translateY: -10 },
          show: { opacity: 1, translateY: 0 },
        }}
      >
        <FontAwesomeIcon
          className="text-white transition-opacity duration-200 opacity-75 group-hover:opacity-100"
          icon={icon.icon}
          size="2x"
        />
      </motion.a>
    ))}
  </motion.div>
);
