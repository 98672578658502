import React from "react";
import Image from "gatsby-image";

import { useStaticQuery, graphql, Link } from "gatsby";

export const Logo: React.FC = () => {
  const data = useStaticQuery(graphql`
    query Logo {
      file(name: { eq: "logo" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <Link to="/" className="block max-w-sm mx-auto">
      <Image fluid={data.file.childImageSharp.fluid} />
    </Link>
  );
};
