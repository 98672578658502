import React from "react";
import classNames from "classnames";
import { motion, HTMLMotionProps } from "framer-motion";
import styled from "styled-components";
import slugify from "slugify";

const StyledSection = styled(motion.section)`
  p {
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:last-of-type {
    margin-bottom: 16vw;
    margin-bottom: clamp(2rem, 16vw, 20rem);
  }
`;

export const Section: React.FC<
  { className?: string; title?: string } & HTMLMotionProps<"ruby">
> = ({ children, className, title, ...props }) => (
  <StyledSection
    id={title ? slugify(title) : undefined}
    variants={{
      hidden: { opacity: 0, translateY: -10, translateZ: 1 },
      show: { opacity: 1, translateY: 0, translateZ: 1 },
    }}
    className={classNames(
      "mt-10 overflow-hidden bg-black text-white bg-opacity-75 backdrop-filter backdrop-blur rounded-lg shadow-lg",
      className
    )}
    {...props}
  >
    {children}
  </StyledSection>
);
